import { useEffect } from "react";
import { Userpilot } from "userpilot";
import { useUser } from "../../apps/cpe-frontend/src/hooks";

Userpilot.initialize("NX-4d4ba934");

export const useUserPilot = () => {
  const { user } = useUser();

  useEffect(() => {
    if (user) {
      Userpilot.identify(user.studentHid, {});
    } else {
      Userpilot.anonymous();
    }
  }, [user]);
};
