import { useUserPilot } from "@cpe/integrations/user-pilot";
import React, { FC, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useFeatureFlags } from "../components/feature-flag/use-feature-flags";
import { useAppDispatch } from "../store";
import { Eula } from "../store/eula/thunks";
import { UserSettingsThunk } from "../store/user-settings/thunks";
import { Routes } from "./routes";

export const APPRouter: FC = () => {
  useUserPilot();
  const { isLoading } = useFeatureFlags();

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(Eula());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserSettings = async () => await dispatch(UserSettingsThunk());

  // TODO: This might not occur after login when change from window.location.href to history.push
  useEffect(() => {
    getUserSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading !== false) {
    return null;
  }
  return (
    <Router>
      <Routes />
    </Router>
  );
};
