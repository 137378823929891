import { LocalStorageType } from "../constants/storage";
import { useStorage } from "../hooks";
import { useAppDispatch } from "../hooks/store";
import { removeStorageItem, storageKeys } from "../store/storage";
import { availableRoutes } from "./routes";

export const useStorageContextCleaner = (pathname: string) => {
  const dispatch = useAppDispatch();
  const { localStorage } = useStorage();

  if (
    !pathname.startsWith(availableRoutes.newComplianceUsers) &&
    !pathname.startsWith(availableRoutes.complianceUsers)
  ) {
    if (localStorage.compliance_users_filters) {
      dispatch(
        removeStorageItem({
          key: LocalStorageType.COMPLIANCE_USERS_FILTERS,
          type: storageKeys.localStorage
        })
      );
    }
  }
};
