import { EDSGlobalStyles, ScreenContext, Tooltip } from "@beckersdp/becker-ds";
import { CPEGTMProvider } from "@cpe/integrations/gtm";
import React, { FC, useEffect, useState } from "react";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import { Toasts } from "./components/toasts";
import { useScreen } from "./hooks/use-screen";
import { APPRouter } from "./router";
import { store } from "./store";
import { GlobalStyles, theme } from "./theme";

let mocksActive = false;

const loadMockServer = async () => {
  const { worker } = await import("./utils/test/api_mocks/browser");
  worker.start();
};

if (process.env.NODE_ENV === "development" && process.env.mocks) {
  mocksActive = true;
}

const App: FC = () => {
  const [mocksLoaded, setMocksLoaded] = useState(false);
  const screen = useScreen();
  const loadMocks = async () => {
    await loadMockServer();
    setMocksLoaded(true);
  };
  useEffect(() => {
    if (mocksActive) {
      loadMocks();
    }
  }, []);

  if (mocksActive && !mocksLoaded) {
    return null;
  }
  return (
    <ThemeProvider theme={theme}>
      <ScreenContext.Provider value={screen}>
        <CPEGTMProvider>
          <Provider store={store}>
            <APPRouter />
            <EDSGlobalStyles />
            <GlobalStyles />
            <Toasts />
            <Tooltip.Manager />
          </Provider>
        </CPEGTMProvider>
      </ScreenContext.Provider>
    </ThemeProvider>
  );
};

export default App;
